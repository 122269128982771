<template>
  <div class="pre-register">
    <Load v-if="isLoading" />
    <v-container v-if="campaignStatus === 'incoming'">
      <v-row>
        <v-col md="6" sm="12" class="gallery">
          <template v-if="thumbnailList.length > 0">
            <PreviewImageSwiper :medias="thumbnailList" />
          </template>
        </v-col>

        <v-col md="6" sm="12">
          <v-row>
            <!--
               TODO: Hide campaign Name date 1 sep 2022
               <v-col cols="12">
              <h1>{{ campaignName }}</h1>
            </v-col> -->
            <v-col cols="12">
              <div v-if="previewHtml" v-html="previewHtml"></div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <div class="pre-register-content">
        <TradeInForm />
      </div>
      <v-row
        v-if="footerHtml"
        v-bind="{ justify: 'center' }"
        class="mt-8"
      >
        <div v-html="footerHtml" class="html-content"></div>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import TradeInForm from '@/components/bnn/TradeInForm'
import PreviewImageSwiper from '@/components/bnn/PreviewImageSwiper'
import Load from './Load'
import loadCampaign from '../utils/loadcampaign'
import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState({
      isLoading: state => state.model.isLoading,
      isLoaded: state => state.model.isLoaded,
      campaign: state => state.campaign.current,
      campaignLoading: state => state.campaign.isLoading,
    }),

    campaignName() {
      return this.campaign?.data?.name
    },

    campaignStatus() {
      return this.campaign?.data?.status ?? ''
    },

    previewHtml() {
      return this.campaign?.data?.preregis_preview_html
    },

    footerHtml() {
      return this.campaign?.data?.preregis_footer_html
    },

    bannerCta() {
      return this.campaign?.data?.banner_cta
    },

    thumbnailList() {
      return (
        [
          {
            id: 1,
            imageUrl: this.bannerCta,
          },
        ] ?? []
      )
    },

    slug() {
      return this.campaign?.data?.slug
    },
  },

  watch: {
    campaignStatus(val) {
      if (val === 'active') {
        this.$router
          .push({
            name: 'ProductDetail',
            params: {
              slug: this.slug,
            },
          })
          .catch(() => true)
      }
    },
  },

  data() {
    return {
      descHtml:
        'iPhone 13 Pro Max การอัปเกรดระบบกล้องระดับโปรครั้งใหญ่ที่สุดเท่าที่เคยมีมา จอภาพ Super Retina XDR พร้อม ProMotion จึงทั้งเร็วขึ้นและรู้สึกได้ถึงการตอบสนองที่ฉับไวยิ่งขึ้น พร้อมชิป A15 Bionic ที่เร็วสุดขั้ว 5G ที่เร็วสุดแรง แถมยังมีดีไซน์ที่ทนทานและแบตเตอรี่ที่ใช้งานได้ดีที่สุดเท่าที่เคยมีมาใน iPhone อีกด้วย - จอภาพ Super Retina XDR ขนาด 6.7 นิ้ว - โหมดภาพยนตร์เพิ่มมิติความชัดตื้นและสลับจุดโฟกัสในวิดีโอของคุณ โดยอัตโนมัติ - ระบบกล้องระดับโปรที่ประกอบด้วยกล้องเทเลโฟโต้, ไวด์ และอัลตร้าไวด์ ความละเอียด 12MP - กล้องหน้า TrueDepth ความละเอียด 12MP - ชิป A15 Bionic เพื่อประสิทธิภาพที่เร็วสุดขั้ว - เล่นวิดีโอ นานสูงสุด 28 ชั่วโมง',
    }
  },

  async mounted() {
    const active = await loadCampaign(this.campaign)
    if (active) {
      document.body.style.overflowY = ''
      document.querySelector('html').style.overflowY = ''
      await this.$store.dispatch('model/getModel', {
        slug: this.slug,
        reload: true,
      })
      this.$store.dispatch('model/reset')
      this.$store.dispatch('bundle/reset')
    }
  },

  components: {
    Load,
    TradeInForm,
    PreviewImageSwiper,
  },
}
</script>

<style lang="stylus" scoped>

.pre-register {
  margin-top: 150px;
}

::v-deep img {
  width: 100%;
}

.pre-register-content {
  // padding-top: 4px
  // margin-top: 4px
  @media screen and (max-width: 960px) {
    padding-top: 8px
    margin-top: 8px
  }
}

.gallery {
  height 100%
  max-height: 500px
  @media screen and (max-width: 960px) {
    height: 500px
    display: flex
    justify-content: center
    width: 100%
  }
}

::v-deep .thumb-gallery{
  height 100%
}
</style>
