<template>
  <div class="pre-register-form">
    <div @click.stop="onClickShow()" :class="btnRegisClass">
      <Button
        v-if="!show"
        @click="onClickShow()"
        block
        depressed
        color="color-bnn"
        height="4px"
        title="ลงทะเบียน Trade In"
      />
    </div>

    <div v-if="show" class="close">
      <v-icon @click.stop="show = false">mdi-close</v-icon>
    </div>
    <div :class="formClass">
      <v-row>
        <form class="form-customer-layout">
          <v-col cols="12" class="text-dark-gray">
            <v-row>
              <v-col cols="12" md="6" class="pb-0">
                <label class="mb-18">
                  ชื่อ <span class="text-red">*</span>
                </label>
                <v-text-field
                  :color="colorBnn['color-bnn']"
                  v-model="form.first_name"
                  background-color="white"
                  data-pptr="first_name"
                  class="input-customer text-sm mt-2"
                  :error="$v.form.first_name.$error"
                  type="text"
                  ref="first_name"
                  @change="persist('firstName', $event)"
                  placeholder="ชื่อ"
                />
              </v-col>
              <v-col cols="12" md="6" class="pb-0">
                <label class="mb-18">
                  นามสกุล <span class="text-red">*</span>
                </label>
                <v-text-field
                  :color="colorBnn['color-bnn']"
                  v-model="form.last_name"
                  background-color="white"
                  data-pptr="last_name"
                  class="input-customer text-sm mt-2"
                  :error="$v.form.last_name.$error"
                  type="text"
                  ref="last_name"
                  @change="persist('lastName', $event)"
                  placeholder="นามสกุล"
                />
              </v-col>
              <v-col cols="12" md="6" class="pb-0">
                <label class="mb-18">
                  อีเมล <span class="text-red">*</span>
                </label>
                <v-text-field
                  :color="colorBnn['color-bnn']"
                  v-model="form.email"
                  background-color="white"
                  data-pptr="email"
                  class="input-customer text-sm mt-2"
                  :error="$v.form.email.$error"
                  type="email"
                  ref="email"
                  @change="persist('email', $event)"
                  placeholder="อีเมล"
                />
              </v-col>
              <v-col cols="12" md="6" class="pb-0">
                <label class="mb-18">
                  เบอร์โทรศัพท์ <span class="text-red">*</span>
                </label>
                <v-text-field
                  :color="colorBnn['color-bnn']"
                  @keypress="isNumber($event)"
                  v-model="form.phone"
                  background-color="white"
                  data-pptr="phone"
                  class="input-customer text-sm mt-2"
                  :error="$v.form.phone.$error"
                  maxlength="10"
                  type="tel"
                  ref="phone"
                  @change="persist('phoneNumber', $event)"
                  placeholder="เบอร์โทรศัพท์"
                />
              </v-col>
              <v-col cols="12" md="12" class="pb-0">
                <label class="mb-18">
                  รุ่นโทรศัพท์
                  <span class="text-red">*</span>
                </label>
                <v-text-field
                  :color="colorBnn['color-bnn']"
                  v-model="form.description"
                  background-color="white"
                  data-pptr="description"
                  class="input-customer text-sm mt-2"
                  :error="$v.form.description.$error"
                  type="text"
                  ref="description"
                  @change="persist('description', $event)"
                  placeholder="รุ่นโทรศัพท์"
                />
              </v-col>

              <div class="my-4"></div>
            </v-row>
          </v-col>
        </form>
      </v-row>
      <v-row
        v-bind="{ justify: 'center', 'no-gutters': true }"
        class="form-customer-layout"
      >
        <v-col md="3" sm="12">
          <Button
            @clickStop="submit()"
            block
            depressed
            color="color-bnn"
            height="4px"
            title="ยืนยันลงทะเบียน Trade In"
          />
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Button from '@/components/bnn/Button'
import colorBnn from '@/config/color'
import { validationMixin } from 'vuelidate'
import {
  required,
  email,
  maxLength,
  numeric,
} from 'vuelidate/lib/validators'

export default {
  mixins: [validationMixin],

  computed: {
    ...mapState({
      campaign: state => state.campaign.current,
    }),

    slug() {
      return this.campaign?.data?.slug ?? ''
    },

    campaignId() {
      return this.campaign?.data?.id ?? ''
    },

    formClass() {
      return {
        'form-layout--show': this.show,
        'form-layout': !this.show,
      }
    },

    btnRegisClass() {
      return {
        'btn-register--show': this.show,
        'btn-register': !this.show,
      }
    },
  },

  data() {
    return {
      show: false,
      colorBnn,
      form: {
        first_name: localStorage.getItem('firstName') || '',
        last_name: localStorage.getItem('lastName') || '',
        phone: localStorage.getItem('phoneNumber') || '',
        email: localStorage.getItem('email') || '',
        description: localStorage.getItem('description') || '',
      },
    }
  },

  validations: {
    form: {
      first_name: { required, maxLength: maxLength(40) },
      last_name: { required, maxLength: maxLength(40) },
      phone: { required, numeric, maxLength: maxLength(20) },
      email: { required, email, maxLength: maxLength(40) },
      description: { required, maxLength: maxLength(100) },
    },
  },

  components: {
    Button,
  },

  methods: {
    onClickShow() {
      this.show = true
    },

    validate() {
      this.$v.$touch()
      if (this.$v.$invalid) {
        for (let key in Object.keys(this.$v.form)) {
          const input = Object.keys(this.$v.form)[key]
          if (input.includes('$')) return false
          if (this.$v.form[input].$error) {
            var elmnt = document.querySelector(
              `[data-pptr='${input}']`,
            )

            elmnt.scrollIntoView({
              // behavior: 'smooth',
              block: 'center',
            })
            if (this?.$refs[input]?.focus) {
              setTimeout(() => this?.$refs[input]?.focus(), 300)
            }
            break
          }
        }
      }
    },

    submit() {
      this.validate()

      if (this.$v.$error) {
        //  console.log('error')
        return
      }

      const payload = {
        first_name: this.form.first_name,
        last_name: this.form.last_name,
        phone: this.form.phone,
        email: this.form.email,
        campaign_id: this.campaignId,
        description: this.form.description,
        type: 'tradin',
      }
      this.$store.dispatch('preRegister/submit', payload)
    },

    persist(key, value) {
      localStorage.setItem(key, value)
    },
  },
}
</script>

<style lang="stylus">

.close
  display none
  @media medium
    display: flex
    justify-content: flex-end
  @media small
    display: flex
    justify-content: flex-end


.customer-form
    background $color-bnn-gray
    padding-top 1rem
    padding-bottom 3rem
    margin-top 4rem
    width 100%
.form-customer-layout
    width 100%
    transition: height 0.25s ease;
    margin-top 1rem
    @media medium
      margin-top 1rem
      width 100%
      padding 0 1rem
    @media small
      margin-top 1rem
      width 100%
      padding 0 1rem
.v-text-field.v-text-field--enclosed .v-text-field__details
  margin-bottom 0 !important
.v-messages.theme--light
  min-height 0px !important
.v-input--selection-controls
  margin-top 0px !important
.input-transparent
  background: transparent !important;
  border-color: transparent !important;
.pre-register-form
  margin-top 0
  @media small
      width: 100%
      background: white
      position fixed
      bottom: 0
      left 0
      z-index: 50
      padding: 2rem 1rem
      box-shadow: 1px 1px 6px #00000029;
      border-radius: 4px
  @media medium
      width: 100%
      background: white
      position fixed
      bottom: 0
      left 0
      z-index: 50
      padding: 2rem 1rem
      box-shadow: 1px 1px 6px #00000029;
      border-radius: 4px

.btn-register
  width 100%
  padding 0 1rem
  display: none
  @media medium
    display: block
  @media small
    display: block

.form-layout
  height auto
  width 100%
  transition: max-height 0.15s ease;
  padding-top: 4px
  @media medium
    height 0px
    padding-top 0
    &--show
      height 640px
      transition: height 0.25s ease;
  @media small
    height 0px
    padding-top 0
    &--show
      height 640px
      transition: height 0.25s ease;
</style>
